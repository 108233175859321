import axios from "axios";
// import {
//   GET_EXPENSES,
//   ADD_EXPENSE,
//   UPDATE_EXPENSE,
//   DELETE_EXPENSE
// } from "./types";

export const getExpenses = () => dispatch => {
  axios
    .get("/expenses")
    .then(res => {
      return dispatch({
        type: "GET_EXPENSES",
        payload: res.data
      });
    })
};

export const addExpense = newExpense => dispatch => {
  axios
    .post("/expenses", newExpense)
    .then(res => {
      return dispatch({
        type: "ADD_EXPENSE",
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};
export const updateExpense = (expenseId, updatedExpense) => dispatch => {
  axios
    .put(`/expenses/${expenseId}`, updatedExpense)
    .then(res => {
      dispatch({
        type: "UPDATE_EXPENSE",
        payload: { id: expenseId, expense: res.data }
      });
    })
    .catch(err => console.error(err));
};

export const deleteExpense = id => dispatch => {
  axios
    .delete(`/expenses/${id}`)
    .then(() => {
      return dispatch({
        type: "DELETE_EXPENSE",
        payload: id
      });
    })
    .catch(err => console.error(err));
};
