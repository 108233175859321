import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import { hideModal } from "../actions/modal";
import checkContactFields from "../validations/contact";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      comment: "",
      errors: {},
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.isInputsValid = this.isInputsValid.bind(this);
  }
  sendMail(msg) {
    axios
      .post("/contact", msg)
      .then(res => this.setState({ submitted: true }))
      .catch(err => this.setState({ errors: err.response.data }));
  }

  resetState() {
    this.setState({
      name: "",
      email: "",
      comment: "",
      submitted: false,
      errors: {}
    });
  }

  handleButtonClick = () => {
    this.props.hideModal();
    this.resetState();
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  handleClick(e) {
    this.resetState();
    this.props.hideModal();
  }

  closeModal(e) {
    e.stopPropagation();
    if (e.target === e.currentTarget) {
      this.resetState();
      this.props.hideModal();
    }
  }

  isInputsValid() {
    const { isValid, errors } = checkContactFields(this.state);
    if (!isValid) return this.setState({ errors });
    return isValid;
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.isInputsValid()) {
      this.sendMail(this.state);
      this.resetState();
    }
  }
  render() {
    const { name, email, comment, submission } = this.state.errors;

    return (
      <div>
        <span id='close-btn' onClick={this.handleClick}>
          &times;
        </span>
        {!this.state.submitted ? (
          <form
            noValidate
            id='contact-form'
            name='contactForm'
            onSubmit={this.handleSubmit}
          >
            <h2 id='contact-heading'>Get In Touch</h2>
            {submission && <div className='error-auth'>{submission}</div>}

            {name && <span className='error-span'>{name}</span>}
            <input
              type='text'
              name='name'
              value={this.state.name}
              placeholder='Name *'
              className='contact-info'
              onChange={this.handleChange}
              id='fullname'
            />

            {email && <span className='error-span'>{email}</span>}
            <input
              type='email'
              name='email'
              value={this.state.email}
              placeholder='Email *'
              className='contact-info'
              onChange={this.handleChange}
              id='email'
            />

            {comment && <span className='error-span'>{comment}</span>}
            <textarea
              name='comment'
              value={this.state.comment}
              placeholder='Message *'
              className='contact-info'
              onChange={this.handleChange}
              id='comment'
            />

            <button className='contact-info' id='submit'>
              Send
            </button>
          </form>
        ) : (
          <p className='result-txt'>
            Thank You <b>{this.state.name}</b> for your message.
            <br />I will get back to you shortly.
            <br />
            <span onClick={this.handleButtonClick} className='modal-link-close'>
              Close
            </span>
          </p>
        )}
      </div>
    );
  }
}

ContactForm.propTypes = {
  hideModal: PropTypes.func.isRequired
};

export default connect(
  null,
  { hideModal }
)(ContactForm);
