import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";

import checkInput from "../../validations/signup";
import { onUserSignup } from "../../actions/signup";
// import { onErrors } from "../../actions/login";
import { addFlashMessage } from "../../actions/flashMessages";
// import InputFieldGroup from '../common/InputField';

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      username: "",
      password: "",
      passwordConfirm: "",
      isLoading: false,
      errors: {}
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  isValid() {
    const { errors, isValid } = checkInput(this.state);
    // console.log(errors + " <-- errors, isvalid --> " + isValid);
    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    
    if (this.isValid()) {
      this.props.onUserSignup(this.state).then(
        res => {
          // console.log(res);
          this.props.addFlashMessage({
            type: "success",
            text: `Thank You ${res.data.toUpperCase()} for signing up. You will get a confirmation email soon.`
          });
          this.context.router.history.push("/");
        },
        err => {
          this.setState({
            isLoading: false,
            errors: err.response.data
          });
        }
      );
    }
  }

  render() {
    const { name, username, password, passwordConfirm } = this.state.errors;
    return (
      <form
        noValidate
        className='job-add-form'
        name='signup'
        onSubmit={this.handleSubmit}
      >
        {/* <InputFieldGroup
          eventHandler={this.handleChange}
          label="Name"
          fieldName="name"
          value={this.state.name}
          error={name}
        /> */}
        <label className='job-add'>
          Name:
          <input
            className={classnames({ "error-border": name })}
            onChange={this.handleChange}
            type='text'
            name='name'
            placeholder='Name'
          />
          {name && <span className='error-span'>{name}</span>}
        </label>

        {/* <InputFieldGroup
          eventHandler={this.handleChange}
          label="Email"
          fieldName="username"
          value={this.state.username}
          error={username}
        /> */}
        <label className='job-add'>
          Email:
          <input
            onChange={this.handleChange}
            className={classnames({ "error-border": username })}
            type='email'
            name='username'
            placeholder='Email'
            autoComplete='username'
          />
          {username && <span className='error-span'>{username}</span>}
        </label>

        {/* <InputFieldGroup
          eventHandler={this.handleChange}
          label="Password"
          type="password"
          fieldName="password"
          value={this.state.password}
          error={password}
        /> */}
        <label className='job-add'>
          Password:
          <input
            onChange={this.handleChange}
            className={classnames({ "error-border": password })}
            type='password'
            name='password'
            placeholder='Password'
            autoComplete='new-password'
          />
          {password && <span className='error-span'>{password}</span>}
        </label>
        {/* <InputFieldGroup
          eventHandler={this.handleChange}
          label="Confirm password"
          type="password"
          fieldName="passwordConfirm"
          value={this.state.passwordConfirm}
          error={passwordConfirm}
        /> */}
        <label className='job-add'>
          Confirm password:
          <input
            onChange={this.handleChange}
            className={classnames({ "error-border": passwordConfirm })}
            type='password'
            name='passwordConfirm'
            placeholder='Re-type password'
            autoComplete='new-password'
          />
          {passwordConfirm && (
            <span className='error-span'>{passwordConfirm}</span>
          )}
        </label>
        <button disabled={this.state.isLoading} className='job-add'>
          SIGN UP
        </button>
      </form>
    );
  }
}

SignupForm.propTypes = {
  onUserSignup: PropTypes.func.isRequired,
  addFlashMessage: PropTypes.func.isRequired
};

SignupForm.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  errors: state.errors.errors
});

export default connect(
  mapStateToProps,
  { onUserSignup, addFlashMessage }
)(SignupForm);
