import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Layout from "./Layout";

class Dashboard extends Component {

  componentWillMount() {
    document.title = "Dashboad | nJustin";
  }

  render() {
    const { name, lastVisit, lastLogin } = this.props.auth.user;
    const date = new Date(lastVisit);
    const lastLoginDate = new Date(lastLogin[0]);

    return (
      <Layout>
        {(new Date(Date.now() - 1500) <= date) && 
          <div className="dashboard-alert">
            Hi <b>{name.split(' ')[0].toUpperCase()}</b>, your last login was on: <br /> {lastLoginDate.toLocaleString()}
          </div >
        }
        <div className='dashboard-container'>
          <Link to='/applications' className='dashboard-label'>
            <div>JAT</div>
            <div>Job Applications Tracker</div>
          </Link>
          <Link to='/users' className='dashboard-label'>
            <div>Management</div>
            <div>List of all registered users</div>  
          </Link>
          <Link to='/xpenses' className='dashboard-label'>
            <div>Expenses</div>
            <div>Expenses gateway</div>
          </Link>
          <Link to='/applications/new' className='dashboard-label'>
            <div>New Application</div>
            <div>Add a new job application</div>
          </Link>
        </div>
      </Layout>
    );    
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {})(Dashboard);
