import React, { Component, Fragment } from "react";
import ExpenseUpdate from "./ExpenseUpdate";

class Expense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      checked: false,
      isVisible: props.isOpen,
      expense: {}
    };
  }

  componentWillMount() {
    this.setState({ expense: this.props.expense });
  }

  handleChange = e => {
    const w = (React.window = window);
    this.setState({
      value: e.target === e.currentTarget ? e.currentTarget.dataset.action : ""
    });
    //if(e.target.value === "update") this.setState({ isVisible: true });
    if (e.target.dataset.action === "remove") {
      if (w.confirm(`${this.state.expense.expense} will be deleted!`)) {
        this.props.deleteExpense(this.props.expense._id);
      }
    } else if (e.target.dataset.action === "update")
      /* this.props.showModal(); */ this.setState({ isVisible: true });
    //this.setState({ checked: !this.state.checked });
  };

  handleActionChange = () => {
    if (this.state.value === "remove")
      this.props.deleteExpense(this.props.expense._id);
    else if (this.state.value === "update") this.props.showModal(); //this.setState({ isVisible: this.props.showModal() });
    this.setState({ value: "" });
  };

  render() {
    const { expense, category, amount, payee, addedOn } = this.state.expense;
    const { isVisible, value, isOpen, checked } = this.state;
    const action = checked ? (
      <span>
        <i className="fas fa-trash-alt"></i>|<i className="fas fa-edit"></i>
      </span>
    ) : null;
    const updateBox = isVisible ? "xpense-update" : "hide";

    // console.log(this.props.isOpen);
    // console.log(isVisible);

    return (
      <Fragment>
        <tr>
          <td className="">{addedOn ? addedOn.split("T")[0] : ""}</td>
          <td className="">
            {expense && expense.length > 10
              ? `${expense.substring(0, 10)}...`
              : expense}
          </td>
          <td className="hide-on-sm capitalize">{category ? category : ""}</td>
          <td className="">
            {amount ? `$${parseFloat(amount).toFixed(2)}` : `$0.00`}
          </td>
          <td className="hide-on-sm capitalize">{payee ? payee : ""}</td>
          <td>
            <input
              type="checkbox"
              value={value}
              onClick={e => this.setState({ checked: !checked })}
            />

            {/* <select value={this.state.value} onChange={this.handleChange}>
              <option value=''>-- Edit --</option>
              <option value='update'>Update</option>
              <option value='remove'>Remove</option>
            </select> */}
            {checked && (
              <span className="xpense-icon" onClick={this.handleActionChange}>
                <i
                  onClick={this.handleChange}
                  className="fas fa-trash-alt"
                  data-action="remove"
                ></i>
                |
                <i
                  onClick={this.handleChange}
                  className="fas fa-edit"
                  data-action="update"
                ></i>
              </span>
            )}
          </td>
        </tr>
        <tr
          onClick={e => {
            if (e.currentTarget === e.target) return this.props.hideModal();
          }}
          className={updateBox}
        >
          {isVisible && (
            <ExpenseUpdate
              isVisible={isVisible}
              hideModal={this.props.hideModal}
              expense={this.props.expense}
            />
          )}
        </tr>
      </Fragment>
    );
  }
}

export default Expense;
