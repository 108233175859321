import React, { Component } from 'react';
import Layout from '../Layout';
import TableHeader from './TableHeader';
import JobList from './JobList';

class Table extends Component {

  componentWillMount() {
    document.title = "Jobs | nJustin";
  }
 
  render() {
    return (
      <Layout>
        <h2 className="container-heading">Jobs applied for</h2>
        <div className="table-wrapper">
          <div className="table-container">
            <TableHeader />
            <JobList />
          </div>
        </div>
      </Layout>
    );
  }
}

export default Table;