import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import MenuLinks from "./MenuLinks";
import logo from "../assets/images/logo-optimized.png";
import njustin from "../assets/images/njustin.png";
import { logout } from "../actions/login";

class Header extends Component {
  constructor() {
    super();
    this.state = { isVisible: false };
  }

  handleMenu = e => {
    if (e) e.stopPropagation();
    this.setState({ isVisible: !this.state.isVisible });
  };
  render() {
    const { isVisible } = this.state;
    const open = isVisible ? "open" : "";
    return (
      <header>
        <Link className="logo" to="/">
          <img src={logo} alt="Logo" id="brand" />
          <div>
            <img src={njustin} alt="nJustin's text logo" />
          </div>
          <span className="horizontal_bar"></span>
        </Link>
        <div className="menu-icon" onClick={this.handleMenu}>
          <div>
            <span className={`bar ${open}`}></span>
          </div>
        </div>
        <MenuLinks open={open} logout={logout} />
      </header>
    );
  }
}

Header.protoTypes = {
  logout: PropTypes.func.isRequired
};

export default Header;
