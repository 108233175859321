import React, { Component } from "react";
// import { connect } from "react-redux";

import Layout from "../Layout";
import LoginForm from "./LoginForm";
// import { login, onErrors } from "../../actions/login";

class LoginPage extends Component {

  componentWillMount() {
    document.title = "Login | nJustin";
  }

  render() {
    return (
      <Layout>
        <div className='table-wrapper signup'>
          <div className='form-wrapper'>
            <h2>LOGIN</h2>
            <LoginForm />
          </div>
        </div>
      </Layout>
    );
  }
}

// LoginPage.propTypes = {
//   login: PropTypes.func.isRequired,
//   onErrors: PropTypes.func.isRequired
// };

// const mapDispatchToProps = dispatch => {
//   return {
//     login: input => dispatch(login(input)),
//     onErrors: errors => dispatch(onErrors(errors))
//   };
// };

// export default connect(
//   null,
//   { login, onErrors }
// )(LoginPage);

export default LoginPage;
