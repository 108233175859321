import React from "react";
import spinner from "../assets/images/img-loader.gif";
const Loading = () => {
  return (
    <div className='loading'>
      Please wait...
      <img src={spinner} alt="spinner" />
    </div>
  );
};


export default Loading;