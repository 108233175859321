import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";

import checkInput from "../../validations/login";
import { login, logout, onErrors } from "../../actions/login";
import { addFlashMessage } from "../../actions/flashMessages";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      errors: {},
      lastVisit: ""
    };
  }

/*  componentWillMount() {
    this.props.logout();
  }
*/
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  onValidate = () => {
    const { errors, isValid } = checkInput(this.state);
    return isValid ? isValid : this.setState({ errors });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.onValidate()) {
      const loginData = {
        username: this.state.username,
        password: this.state.password
      };

      this.props.login(loginData)
        .catch(err => {
          this.setState({ 
            errors: err.response.data, 
            isLoading: false 
          });
        })
        // this.props.addFlashMessage({
        //   type: "success",
        //   text: `Your last visit was on ${this.state.lastVisit}`
        // });
      // this.context.router.history.push("/dashboard")
    }
  };

  render() {
    const { username, password, auth } = this.state.errors;
    if(this.props.auth.isAuthenticated) {
      return <Redirect to="/dashboard" />
    }
    return (
      <form
        noValidate
        className='job-add-form'
        name='login'
        onSubmit={this.handleSubmit}
      >
        {/* <InputFieldGroup
          eventHandler={this.handleChange}
          label="Email"
          fieldName="username"
          value={this.state.username}
          error={username}
        /> */}
        {auth && <div className='error-auth'>{auth}</div>}
        <label className='job-add'>
          Email:
          <input
            onChange={this.handleChange}
            className={classnames({ "error-border": username })}
            type='email'
            name='username'
            placeholder='Email'
            autoComplete='username'
          />
          {username && <span className='error-span'>{username}</span>}
        </label>

        {/* <InputFieldGroup
          eventHandler={this.handleChange}
          label="Password"
          type="password"
          fieldName="password"
          value={this.state.password}
          error={password}
        /> */}
        <label className='job-add'>
          Password:
          <input
            onChange={this.handleChange}
            className={classnames({ "error-border": password })}
            type='password'
            name='password'
            placeholder='Password'
            autoComplete='new-password'
          />
          {password && <span className='error-span'>{password}</span>}
        </label>
        <button className='job-add'>SIGN IN</button>
      </form>
    );
  }
}

// LoginForm.propTypes = {
//   login: PropTypes.func.isRequired
// };

// LoginForm.contextTypes = {
//   router: PropTypes.object.isRequired
// };

const mapStateToProps = state => ({
  errors: state.errors,
  // isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { login, logout, onErrors, addFlashMessage }
)(LoginForm);


// export default LoginForm;
