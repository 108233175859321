import React, { Component } from "react";
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with errorsmessage
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    // You can also log errorsmessages to an errorsreporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // errorspath
      return (
        <div className='errorBoundary'>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.errors && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
