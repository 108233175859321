import { combineReducers } from "redux";

import flashMessages from "./reducers/flashMessages";
import auth from "./reducers/auth";
import modal from "./reducers/modal";
import jobs from "./reducers/application";
import expenses from "./reducers/expense";
import errors from "./reducers/errors";

export default combineReducers({
  flashMessages,
  auth,
  isOpen: modal,
  jobs,
  expenses,
  errors
});
