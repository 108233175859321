import React, { Component } from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";

import { updateExpense } from "../../actions/expense";
class ExpenseUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expense: props.expense.expense,
      category: props.expense.category,
      amount: parseFloat(props.expense.amount).toFixed(2),
      payee: props.expense.payee,
      note: props.expense.note,
      addedOn: props.expense.addedOn,
      isVisible: props.isVisible
    };
  }

  //componentDidUpdate() {
  //  this.setState({ isVisible: this.props.isVisible });
 // }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  handleCancel = () => {
    this.setState({ isVisible: false });
    //console.log(this.state.isVisible)
  }

  handleUpdate = () => {
    this.props.updateExpense(this.props.expense._id, this.state);
    this.setState({ isVisible: false });
  };
  render() {
    const { expense, category, amount, payee, note, addedOn, isVisible } = this.state;
    const hideView = !this.state.isVisible ? "xpense-update-component" : "xpense-update-component hide";
    // console.log(hideView);
    // console.log(this.props)
    // console.log(this.state.isVisible)

    return (
      <td className={hideView}>
        <label className='xpense-update-label'>
          <span>Expense:</span>
          <br />
          <input
            value={expense}
            onChange={this.handleChange}
            type='text'
            name='expense'
            placeholder='Expense'
          />
        </label>
        <label className='xpense-update-label'>
          <span>category:</span>
          <br />
          <select name='category' value={category} onChange={this.handleChange}>
            <option value=''>-- Select --</option>
            <option value='groceries'>Groceries</option>
            <option value='shopping'>Shopping</option>
            <option value='entertainment'>Entertainment</option>
            <option value='auto'>Auto</option>
            <option value='knowledge'>Knowledge</option>
            <option value='payment'>Payment</option>
            <option value='electronics'>Electronics</option>
            <option value='donation'>Donation</option>
            <option value='miscellaneous'>Miscellaneous</option>
            <option value='bills'>Bills</option>
            <option value='income'>Income</option>
            <option value='transfer'>Transfer</option>
            <option value='outing'>Outing</option>
            <option value='household'>Household</option>
          </select>
        </label>
        <label className='xpense-update-label'>
          <span>Amount:</span>
          <br />
          <input
            value={amount}
            onChange={this.handleChange}
            type='number'
            name='amount'
            placeholder='Amount'
            autoComplete='amount'
          />
        </label>
        <label className='xpense-update-label'>
          <span>Payee:</span>
          <br />
          <input
            value={payee}
            onChange={this.handleChange}
            type='text'
            name='payee'
            placeholder='Payee'
            autoComplete='payee'
          />
        </label>
        <label className='xpense-update-label'>
          <span>Note:</span>
          <br />
          <textarea
            value={note}
            onChange={this.handleChange}
            name='note'
            placeholder='Note'
            autoComplete='note'
          />
        </label>

        <label className='xpense-update-label'>
          <span>Date:</span>
          <br />
          <input
            value={addedOn}
            onChange={this.handleChange}
            type='date'
            name='addedOn'
            autoComplete='addedOn'
          />
        </label>
        <button className='xpense-update-btn noaction' onClick={this.handleCancel}>
          Cancel 
        </button>
        <button className='xpense-update-btn' onClick={this.handleUpdate}>
          SAVE
        </button>
      </td>
    );
  }
}

const mapStateToProps = state => ({
  expenses: state.expenses.expsenses
});

export default connect(
  mapStateToProps,
  { updateExpense }
)(ExpenseUpdate);
