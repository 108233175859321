import Validator from 'validator';
import isObjEmpty from 'lodash/isEmpty';

const checkInput = (inputData) => {
  const errors = {};
  const { isEmpty, isEmail } = Validator;
  //if(isEmpty(inputData.username)) errors.username = 'Email is required';
  if(!isEmail(inputData.username)) errors.username = 'Invalid email address';
  if(isEmpty(inputData.password)) errors.password = 'Password is required';

  return {
    errors,
    isValid: isObjEmpty(errors)
  }
}

export default checkInput;
