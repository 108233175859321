import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../actions/login";
import { hideModal } from "../actions/modal";
class MenuLinks extends Component {

  onLogout = e => {
    e.preventDefault();
    this.props.logout();
    // this.context.router.history.push("/");
    this.props.hideModal();
    return <Redirect to={{
      pathname: '/'
    }} />
  }
  render() {
    const { isAuthenticated } = this.props.auth;

    const guestAuth = (
      <div className='auth-wrapper'>
        <NavLink to='/users/new' className='auth' activeClassName='selected'>
          Sign up
        </NavLink>
        <NavLink to='/users/login' className='auth' activeClassName='selected'>
          Sign in
        </NavLink>
      </div>
    );

    return (
      <div className={`menu-wrapper ${this.props.open}`}>
        {isAuthenticated && (
          <div>
            <Link to='/' className='menu-link'>
              Home
            </Link>
            <Link to='/dashboard' className='menu-link'>
              Dashboard
            </Link>
            <Link to='/applications' className='menu-link'>
              Applications
            </Link>
            <Link to='/xpenses' className='menu-link'>
              Expenses
            </Link>
            <Link to='/applications/new' className='menu-link'>
              New job
            </Link>
            <Link to='/users' className='menu-link'>
              All users
            </Link>
          </div>
        )}
        {isAuthenticated ? (
          <button className='auth logout' onClick={this.onLogout}>
            Logout
          </button>
        ) : (
          guestAuth
        )}
      </div>
    );
  }
}

MenuLinks.propTypes = {
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(
  mapStateToProps,
  { logout, hideModal }
)(MenuLinks);
