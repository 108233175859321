import Validator from "validator";
import isObjEmpty from "lodash/isEmpty";

const checkContactFields = input => {
  const errors = {};
  const { isEmpty, isEmail } = Validator;
  if (isEmpty(input.name)) errors.name = "Name is required!";
  if (!isEmail(input.email)) errors.email = "Enter your email address!";
  if (isEmpty(input.comment)) errors.comment = "Type your message!";

  return {
    errors,
    isValid: isObjEmpty(errors)
  };
};

export default checkContactFields;
