import Validator from 'validator';
import isObjEmpty from 'lodash/isEmpty';

const checkInput = (inputData) => {
  const errors = {};
  const { isEmpty, isEmail, equals } = Validator;
  if(isEmpty(inputData.name)) {errors.name = 'Name is required';}
  if(isEmpty(inputData.username)) {errors.username = 'Email is required';}
  if(!isEmail(inputData.username)) {errors.username = 'Invalid email address';}
  if(isEmpty(inputData.password)) {errors.password = 'Password is required';}
  if(isEmpty(inputData.passwordConfirm)) {errors.passwordConfirm = 're-type your password';}
  if(!equals(inputData.password, inputData.passwordConfirm)) {errors.passwordConfirm = 'Passwords don\'t match';}

  return {
    errors,
    isValid: isObjEmpty(errors)
  }
}

export default checkInput;
