import React, { Component } from "react";
//import PropTypes from "prop-types";
import { connect } from "react-redux";
// import classnames from "classnames";

//import checkExpenseInput from "../../validations/expense";
import { addExpense } from "../../actions/expense";

class ExpenseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expense: "",
      category: "",
      amount: 0,
      payee: "",
      addedOn: new Date(),
      isHidden: true
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
/*    if(e.target.type === 'date'){
      const d = value.split('-');
      value..
    } */
    this.setState({
      [name]: value
    });
    // console.log(e.target.type)
    // console.log(value)
  };

  handleSubmit = e => {
    e.preventDefault();
    if(this.state.expense === "") return;
    this.props.addExpense(this.state);
    return document.forms.transaction.reset();
  };

  render() {
    //    const { expense, category, amount, payee, addedOn } = this.state.errors;
    const formDisplay = this.state.isHidden ? 'none' : 'block';
    const formViewIcon = this.state.isHidden ? '+' : '-';
    const formLabel = this.state.isHidden ? 'Add new expense' : 'Hide the form';
    return (
      <div className='xpense'>
        <div onClick={() => this.setState({ isHidden: !this.state.isHidden })}>{formLabel}<span>{formViewIcon}</span></div>
        <form
          noValidate
          className=''
          style={{ display: formDisplay }}
          name='transaction'
          onSubmit={this.handleSubmit}
        >
          <label className='xpense-form-label'>
            <span>Expense:</span>
            <br />
            <input
              onChange={this.handleChange}
              type='text'
              name='expense'
              placeholder='Expense'
            />
          </label>
          <label className='xpense-form-label'>
            <span>category:</span>
            <br />
            <select
              name='category'
              value={this.state.category}
              onChange={this.handleChange}
            >
            <option value=''>-- Select --</option>
            <option value='groceries'>Groceries</option>
            <option value='shopping'>Shopping</option>
            <option value='entertainment'>Entertainment</option>
            <option value='auto'>Auto</option>
            <option value='knowledge'>Knowledge</option>
            <option value='payment'>Payment</option>
            <option value='electronics'>Electronics</option>
            <option value='donation'>Donation</option>
            <option value='miscellaneous'>Miscellaneous</option>
            <option value='bills'>Bills</option>
            <option value='income'>Income</option>
            <option value='transfer'>Transfer</option>
            <option value='outing'>Outing</option>
            <option value='household'>Household</option>
            </select>
          </label>
          <label className='xpense-form-label'>
            <span>Amount:</span>
            <br />
            <input
              onChange={this.handleChange}
              type='number'
              name='amount'
              placeholder='Amount'
              autoComplete='amount'
            />
          </label>
          <label className='xpense-form-label'>
            <span>Payee:</span>
            <br />
            <input
              onChange={this.handleChange}
              type='text'
              name='payee'
              placeholder='Payee'
              autoComplete='payee'
            />
          </label>
          <label className='xpense-form-label'>
            <span>Date:</span>
            <br />
            <input
              onChange={this.handleChange}
              type='date'
              name='addedOn'
	      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
              autoComplete='addedOn'
            />
          </label>
          <button className='xpense-form-btn'>SAVE</button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  expenses: state.expenses.expenses
});

export default connect(
  mapStateToProps,
  { addExpense }
)(ExpenseForm);
