import React from "react";

export const Logo = props => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="120px"
      height="50px"
      viewBox="0 0 1280.000000 1280.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M5550 12780 c-1104 -148 -2118 -562 -2998 -1223 -1424 -1068 -2338
        -2677 -2521 -4437 -32 -307 -42 -823 -21 -1115 78 -1078 395 -2050 968 -2967
        378 -605 911 -1193 1487 -1639 876 -679 1898 -1116 2975 -1273 376 -55 464
        -60 945 -60 481 0 569 5 945 60 849 123 1668 423 2415 883 706 435 1349 1035
        1837 1718 693 968 1091 2074 1178 3278 13 180 13 700 0 880 -87 1196 -484
        2307 -1167 3263 -1045 1464 -2644 2419 -4413 2637 -102 12 -250 15 -810 14
        -602 -1 -701 -3 -820 -19z m3110 -4792 c0 -2284 -2 -2597 -15 -2703 -30 -227
        -89 -465 -174 -695 -38 -103 -137 -305 -186 -378 -19 -30 -35 -56 -35 -59 0
        -15 -162 -202 -217 -251 -76 -67 -193 -151 -210 -152 -7 0 -13 -7 -13 -15 0
        -9 -9 -15 -23 -15 -20 0 -25 11 -51 123 -16 67 -33 126 -37 131 -5 6 -9 22 -9
        37 0 14 -4 39 -10 55 -5 16 -12 45 -14 64 -10 68 -23 120 -36 135 -7 8 -10 23
        -8 33 3 11 -1 30 -8 43 -7 13 -13 38 -14 56 0 17 -4 35 -10 38 -5 3 -10 18
        -10 32 0 14 -6 51 -14 82 -7 31 -19 83 -26 116 -8 33 -17 65 -20 70 -7 9 -15
        52 -23 118 -2 15 -9 33 -15 39 -7 7 -12 23 -12 36 0 14 -4 41 -10 61 -5 20
        -21 87 -35 150 -13 63 -33 144 -44 180 -11 36 -27 100 -36 141 -8 41 -20 91
        -25 110 -4 19 -27 114 -49 210 -40 170 -48 201 -70 285 -6 22 -18 74 -27 115
        -9 41 -23 100 -30 130 -8 30 -14 62 -14 72 0 10 -4 26 -9 36 -14 27 -41 147
        -41 181 0 17 -4 31 -9 31 -5 0 -11 15 -14 33 -3 17 -13 64 -22 102 -9 39 -17
        80 -19 92 -1 13 -6 26 -9 30 -4 3 -7 20 -7 38 0 17 -5 36 -11 42 -6 6 -15 37
        -20 69 -6 33 -13 74 -16 92 -3 18 -9 35 -14 38 -5 3 -9 20 -9 38 0 17 -4 36
        -8 41 -5 6 -15 44 -22 85 -7 41 -19 87 -27 102 -7 15 -13 38 -13 52 0 14 -7
        51 -16 83 -9 32 -19 75 -21 96 -3 20 -9 40 -14 43 -5 3 -9 17 -9 32 0 15 -6
        47 -14 72 -8 25 -25 90 -37 145 -12 55 -25 109 -29 120 -4 11 -13 45 -19 75
        -6 30 -13 66 -15 80 -3 14 -13 61 -22 105 -8 44 -22 92 -30 107 -8 14 -14 40
        -14 56 0 17 -4 34 -10 37 -5 3 -10 20 -10 36 0 17 -7 43 -15 60 -9 16 -14 33
        -11 37 6 11 -23 145 -34 156 -6 6 -10 27 -10 47 0 21 -4 40 -9 43 -5 3 -11 20
        -14 38 -24 135 -50 248 -71 308 -9 25 -16 59 -16 77 0 17 -4 34 -9 37 -5 3
        -11 20 -14 38 -3 18 -10 51 -15 73 -6 22 -15 67 -21 100 -7 33 -17 70 -23 82
        -6 13 -9 26 -5 29 3 4 0 19 -8 35 -8 15 -15 41 -15 58 0 16 -4 33 -10 36 -5 3
        -10 19 -10 34 0 15 -6 50 -14 77 -7 27 -19 76 -26 109 -7 33 -17 64 -22 70 -4
        5 -8 21 -8 36 0 15 -4 30 -9 34 -6 3 -9 24 -9 46 0 22 -5 46 -11 52 -6 6 -11
        27 -11 47 0 20 -4 40 -8 46 -16 17 -32 91 -32 143 l0 51 1260 0 1260 0 0
        -2582z m-2879 960 c6 -23 14 -62 19 -87 4 -26 15 -73 23 -106 9 -33 23 -91 32
        -130 37 -165 58 -251 66 -270 4 -11 10 -36 12 -55 3 -19 17 -84 32 -145 14
        -60 30 -128 34 -150 4 -22 15 -67 24 -100 9 -33 33 -134 53 -225 20 -91 39
        -172 42 -180 3 -8 7 -26 9 -40 2 -14 8 -38 12 -55 5 -16 16 -66 26 -110 9 -44
        21 -88 26 -97 5 -10 9 -27 9 -39 0 -12 5 -40 11 -63 14 -54 25 -100 38 -156
        14 -62 57 -247 82 -350 70 -298 89 -381 89 -395 0 -8 4 -23 9 -33 5 -9 13 -39
        19 -67 22 -111 45 -211 52 -230 4 -11 18 -65 30 -120 50 -227 53 -242 109
        -475 11 -47 27 -116 36 -155 8 -38 19 -86 24 -105 19 -70 93 -391 105 -451 4
        -20 14 -63 36 -149 10 -41 26 -103 34 -137 9 -35 16 -72 16 -83 0 -11 4 -28 9
        -38 8 -15 22 -75 37 -158 3 -16 12 -54 21 -83 13 -48 42 -170 59 -251 3 -14
        14 -61 24 -105 10 -44 26 -114 36 -155 9 -41 19 -82 22 -90 2 -8 6 -22 7 -30
        8 -45 38 -172 51 -217 8 -29 16 -62 18 -75 4 -43 29 -143 38 -153 4 -5 8 -21
        8 -36 0 -15 4 -39 10 -54 5 -14 12 -43 15 -63 2 -20 12 -64 21 -97 9 -33 20
        -80 25 -105 5 -24 16 -69 23 -100 8 -30 22 -89 31 -130 9 -41 19 -83 22 -92 4
        -17 -35 -18 -710 -18 -781 0 -727 -4 -727 55 0 13 -4 27 -10 30 -5 3 -10 19
        -10 35 0 16 -4 40 -9 53 -5 13 -12 37 -14 53 -3 16 -14 65 -25 109 -11 44 -30
        125 -42 180 -13 55 -31 132 -40 170 -10 39 -26 106 -35 150 -9 44 -21 89 -25
        100 -5 11 -13 49 -20 85 -6 36 -22 106 -35 155 -12 50 -28 117 -35 150 -6 33
        -16 68 -21 77 -5 10 -9 27 -9 38 0 11 -6 46 -14 78 -8 31 -24 95 -35 142 -11
        47 -23 99 -27 115 -4 17 -8 36 -10 43 -4 21 -23 102 -45 192 -12 47 -22 94
        -24 105 -2 11 -10 49 -20 85 -9 36 -21 83 -25 105 -5 22 -21 90 -35 150 -14
        61 -28 124 -31 140 -3 17 -8 37 -11 45 -3 8 -7 24 -9 35 -2 11 -8 36 -13 55
        -5 19 -17 71 -27 115 -9 44 -25 112 -35 151 -11 38 -19 82 -19 97 0 15 -4 27
        -9 27 -4 0 -11 19 -14 43 -4 23 -18 92 -32 152 -14 61 -32 142 -41 180 -8 39
        -19 79 -24 90 -4 11 -11 36 -14 55 -3 19 -17 85 -32 145 -14 61 -28 124 -31
        140 -2 17 -7 37 -10 45 -3 8 -7 22 -9 30 -2 8 -7 33 -13 55 -12 48 -58 250
        -73 320 -5 28 -14 58 -19 67 -5 10 -9 27 -9 39 0 11 -6 44 -14 72 -7 29 -23
        95 -36 147 -12 52 -30 131 -40 175 -11 44 -28 121 -40 170 -46 205 -53 231
        -60 250 -5 11 -11 36 -14 55 -5 32 -35 164 -72 320 -9 36 -17 72 -19 80 -1 8
        -5 22 -8 30 -3 8 -12 51 -22 95 -9 44 -23 105 -30 135 -8 31 -19 76 -24 100
        -10 43 -32 138 -66 285 -9 39 -20 88 -25 110 -5 22 -14 55 -19 73 -5 17 -7 32
        -3 32 3 0 1 5 -6 12 -7 7 -12 22 -12 33 0 12 -9 57 -20 100 -11 43 -20 82 -20
        87 0 4 322 8 715 8 l714 0 12 -42z m-1425 -3075 c3 -16 9 -37 14 -48 4 -11 14
        -54 20 -95 7 -41 16 -79 20 -85 4 -5 10 -28 13 -50 5 -37 30 -153 78 -355 11
        -47 24 -105 29 -130 5 -25 14 -70 20 -100 6 -30 14 -62 17 -70 4 -8 8 -24 9
        -35 1 -11 14 -69 29 -130 15 -60 29 -123 31 -140 3 -16 9 -48 14 -70 5 -22 19
        -80 30 -130 11 -49 27 -115 34 -145 8 -30 22 -91 31 -135 19 -99 66 -307 110
        -493 8 -35 15 -72 15 -83 0 -11 4 -27 9 -37 9 -16 31 -114 36 -158 3 -25 43
        -194 56 -240 5 -16 12 -56 15 -87 l6 -57 -447 0 -448 0 7 113 c3 61 10 164 16
        227 5 63 12 160 15 215 3 55 10 133 15 173 6 40 10 111 10 158 0 46 4 92 9
        102 8 15 27 212 38 407 2 33 10 137 18 230 8 94 19 241 25 328 5 87 12 162 15
        166 2 4 11 100 20 213 8 112 17 229 20 259 12 128 25 285 25 297 0 23 20 12
        26 -15z"
        />
      </g>
    </svg>
  );
};

export const HomeIcom = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    width="20px"
    height="20px"
    viewBox="0 0 460.298 460.297"
    // style="enable-background:new 0 0 460.298 460.297;"
    space="preserve"
  >
    <g>
      <g>
        <path
          d="M230.149,120.939L65.986,256.274c0,0.191-0.048,0.472-0.144,0.855c-0.094,0.38-0.144,0.656-0.144,0.852v137.041    c0,4.948,1.809,9.236,5.426,12.847c3.616,3.613,7.898,5.431,12.847,5.431h109.63V303.664h73.097v109.64h109.629    c4.948,0,9.236-1.814,12.847-5.435c3.617-3.607,5.432-7.898,5.432-12.847V257.981c0-0.76-0.104-1.334-0.288-1.707L230.149,120.939    z"
          fill="#FFFFFF"
        />
        <path
          d="M457.122,225.438L394.6,173.476V56.989c0-2.663-0.856-4.853-2.574-6.567c-1.704-1.712-3.894-2.568-6.563-2.568h-54.816    c-2.666,0-4.855,0.856-6.57,2.568c-1.711,1.714-2.566,3.905-2.566,6.567v55.673l-69.662-58.245    c-6.084-4.949-13.318-7.423-21.694-7.423c-8.375,0-15.608,2.474-21.698,7.423L3.172,225.438c-1.903,1.52-2.946,3.566-3.14,6.136    c-0.193,2.568,0.472,4.811,1.997,6.713l17.701,21.128c1.525,1.712,3.521,2.759,5.996,3.142c2.285,0.192,4.57-0.476,6.855-1.998    L230.149,95.817l197.57,164.741c1.526,1.328,3.521,1.991,5.996,1.991h0.858c2.471-0.376,4.463-1.43,5.996-3.138l17.703-21.125    c1.522-1.906,2.189-4.145,1.991-6.716C460.068,229.007,459.021,226.961,457.122,225.438z"
          fill="#FFFFFF"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export const DeleteIcon = props => {
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="15px"
      height="15px"
      viewBox="0 0 408.483 408.483"
      space="preserve"
    >
      <g>
        <g>
          <path
            d="M87.748,388.784c0.461,11.01,9.521,19.699,20.539,19.699h191.911c11.018,0,20.078-8.689,20.539-19.699l13.705-289.316
            H74.043L87.748,388.784z M247.655,171.329c0-4.61,3.738-8.349,8.35-8.349h13.355c4.609,0,8.35,3.738,8.35,8.349v165.293
            c0,4.611-3.738,8.349-8.35,8.349h-13.355c-4.61,0-8.35-3.736-8.35-8.349V171.329z M189.216,171.329
            c0-4.61,3.738-8.349,8.349-8.349h13.355c4.609,0,8.349,3.738,8.349,8.349v165.293c0,4.611-3.737,8.349-8.349,8.349h-13.355
            c-4.61,0-8.349-3.736-8.349-8.349V171.329L189.216,171.329z M130.775,171.329c0-4.61,3.738-8.349,8.349-8.349h13.356
            c4.61,0,8.349,3.738,8.349,8.349v165.293c0,4.611-3.738,8.349-8.349,8.349h-13.356c-4.61,0-8.349-3.736-8.349-8.349V171.329z"
          />
          <path
            d="M343.567,21.043h-88.535V4.305c0-2.377-1.927-4.305-4.305-4.305h-92.971c-2.377,0-4.304,1.928-4.304,4.305v16.737H64.916
            c-7.125,0-12.9,5.776-12.9,12.901V74.47h304.451V33.944C356.467,26.819,350.692,21.043,343.567,21.043z"
          />
        </g>
      </g>
    </svg>
  );
};

export const EditIcon = props => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="15px"
      height="15px"
      viewBox="0 0 512 512"
      space="preserve"
    >
      <g>
        <g>
          <path
            d="M389.513,87.422c0-12.012-4.688-23.32-13.184-31.816l-42.422-42.422C325.529,4.805,313.636,0,301.8,0h-2.578v90h90.292
            L389.513,87.422L389.513,87.422z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M273.937,309.537c2.871-8.716,7.881-16.831,14.414-23.408l101.562-101.153V120h-105.4c-8.291,0-14.513-6.709-14.513-15V0
            H45C20.186,0,0,20.186,0,45v422c0,24.814,20.186,45,45,45h299.513c24.814,0,45.4-20.186,45.4-45V355.049l-16.484,16.084
            c-6.679,6.621-14.501,11.44-23.32,14.385l-47.695,15.923l-7.266,0.396c-12.012,0-23.379-5.845-30.439-15.63
            c-7.002-9.741-8.906-22.368-5.098-33.779L273.937,309.537z M75,270h149.513c8.291,0,15,6.709,15,15c0,8.291-6.709,15-15,15H75
            c-8.291,0-15-6.709-15-15C60,276.709,66.709,270,75,270z M60,225c0-8.291,6.709-15,15-15h149.513c8.291,0,15,6.709,15,15
            s-6.709,15-15,15H75C66.709,240,60,233.291,60,225z M60,345c0-8.291,6.709-15,15-15h149.513c8.291,0,15,6.709,15,15
            c0,8.291-6.709,15-15,15H75C66.709,360,60,353.291,60,345z M284.513,420c8.291,0,15,6.709,15,15c0,8.291-6.708,15-15,15h-90
            c-8.291,0-15-6.709-15-15c0-8.291,6.709-15,15-15H284.513z M75,180c-8.291,0-15-6.709-15-15s6.709-15,15-15h209.513
            c8.291,0,15,6.709,15,15s-6.709,15-15,15H75z"
          />
        </g>
      </g>
      <g>
        <g>
          <path d="M301.111,322.808l-13.05,39.151c-1.956,5.865,3.625,11.444,9.49,9.485l39.128-13.068L301.111,322.808z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M417.609,199.307l-98.789,98.789l42.605,42.605c22.328-22.332,65.773-65.783,98.784-98.794L417.609,199.307z" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M503.185,156.284c-5.273-5.303-13.037-8.335-21.27-8.335c-8.233,0-15.996,3.032-21.299,8.35l-21.797,21.797l42.598,42.598
            c11.933-11.934,20.181-20.182,21.799-21.799C514.933,187.16,514.932,168.046,503.185,156.284z"
          />
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              d="M503.215,198.896c0.001,0,0.001-0.001,0.002-0.002c0.038-0.038,0.055-0.055,0.086-0.086
              C503.272,198.84,503.255,198.857,503.215,198.896z"
            />
            <path d="M503.303,198.808c0.048-0.048,0.104-0.104,0.133-0.133C503.406,198.705,503.351,198.76,503.303,198.808z" />
            <path d="M503.436,198.675C503.533,198.578,503.535,198.576,503.436,198.675L503.436,198.675z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

// this propperty should be included in the svg tag
// style="enable-background:new 0 0 512 512;"
