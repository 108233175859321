import React from 'react';
import { stringFormat } from "../../utils/helper";

const JobAppDetail = (props) => {
  const { title, company, description, notes, skills, appliedOn, feedback} = props.job;
  const dateApplied = appliedOn ? appliedOn.split('T').slice(0,1) : '';
  const desc = description ? description : '';
  const note = notes ? notes : '';

// console.log(stringFormat(desc))

 return (
    <ul className={props.onExpanded}>
      <div className="jb-detail">
        <span><b>Position:</b></span><span>&nbsp;{title}</span>
      </div>
      <div className="jb-detail">
        <span><b>Company: </b></span><span>&nbsp;{company}</span>
      </div>
      <div className="jb-detail">
        <span><b>Skills: </b></span><span>&nbsp;{skills}</span>
      </div>
      <div className="jb-detail">
        <span><b>Applied on: </b></span><span>&nbsp;{dateApplied}</span>
      </div>
      <div className="jb-detail">
        <span><b>Feedback: </b></span><span>&nbsp;{feedback}</span>
      </div>
      <div className="jb-detail">
        <span><b>Notes: </b></span><span>&nbsp;{note}</span>
      </div>
      <div className="jb-detail">
        <span><b>Job description: </b></span><br /><div>{stringFormat(desc)}</div>
      </div>
    </ul>
  );
}

export default JobAppDetail;
