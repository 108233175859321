import React, { Component, Suspense } from "react";

import ErrorBoundary from "./ErrorBoundary";
import Header from "./Header";
import Footer from "./Footer";
//import FlashMessagesList from "../flash/FlashMessagesList";

import loadingSpinner from "../assets/images/img-loader.gif";

const Loading = () => {
  return (
    <div className='loading'>
      <img src={loadingSpinner} alt='Spinner' />
    </div>
  );
};

class Layout extends Component {
  render() {
    return (
      <ErrorBoundary>
        <div className='container'>
          <Header />
          <div className='main-content'>
{/*            <FlashMessagesList />   */}
            <Suspense fallback={<Loading />}>{this.props.children}</Suspense>
          </div>
          <Footer />
        </div>
      </ErrorBoundary>
    );
  }
}

export default Layout;
