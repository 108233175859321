import React from "react";
import Layout from "../Layout";

import ExpenseForm from "./ExpenseForm";
import ExpensesList from "./ExpensesList";

export default function index() {
  return (
    <Layout>
      <ExpenseForm />
      <ExpensesList />
    </Layout>
  );
}
