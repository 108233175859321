import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// import selfImg from "../assets/images/self.png";
import selfImg from "../assets/images/graphicHomePage.JPG";
import Modal from "./Modal";
import { showModal } from "../actions/modal";

class Main extends Component {
  componentWillMount() {
    document.title = "Home | nJustin";
  }

  handleClick = e => {
    e.preventDefault();
    this.props.showModal();
  };

  render() {
    return (
      <main>
        <div className="home-img">
          <img src={selfImg} alt="Home page" />
        </div>
        <div className="home-txt">
          <h1>Hi, I'm N. Justin.</h1>
          <p className="description">
            <span>My areas of interest are:</span>
            <br />
            &nbsp;&#10003;&nbsp;Mobile Apps Development
            <br />
            &nbsp;&#10003;&nbsp;Full Stack Development
            <br />
            &nbsp;&#10003;&nbsp;Systems Administration
            <br />
            &nbsp;&#10003;&nbsp;DevOps Engineering
          </p>
          <p className="home-txt-p2">
            I'm currently not available
            <br />
            for new projects!
          </p>
          <div className="contact" onClick={this.handleClick}>
            Contact Me
          </div>
        </div>
        <Modal />
      </main>
    );
  }
}

const mapStateToProps = state => ({
  isOpen: state.isOpen
});

Main.propType = {
  isOpen: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired
};

export default connect(mapStateToProps, { showModal })(Main);
