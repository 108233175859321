import React from 'react';
const Footer = () => {
  const date = new Date();
  const currentYear = date.getFullYear();
  return (
    <footer>
      &copy;&nbsp;<span id="copyright">{currentYear}</span>
      &nbsp;<b>nJustin</b>.
      &nbsp;All Rights Reserved.
    </footer>
  )
}

export default Footer;