import axios from "axios";
import { SET_CURRENT_USER, GET_ERRORS } from "./types";
import setAuthorizationToken from "../utils/setAuthorizationToken";
import jwt from "jsonwebtoken";

export const setCurrentUser = user => {
  return {
    type: SET_CURRENT_USER,
    user
  };
};

export const onErrors = errors => {
  return {
    type: GET_ERRORS,
    payload: errors
  };
};

export const login = user => dispatch => {
  return axios
    .post("/login", user)
    .then(res => {
      const token = res.data.token;
      localStorage.setItem("jwtToken", token);
      setAuthorizationToken(token);
      // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      dispatch(setCurrentUser(jwt.decode(token)));
    })
};

export const logout = () => dispatch => {
  localStorage.removeItem("jwtToken");
  setAuthorizationToken();
  // delete axios.defaults.headers.common["Authorization"];
  dispatch(setCurrentUser({}));
};
