import {
  SET_CURRENT_USER,
  GET_USERS,
  UPDATE_USER,
  DELETE_USER
} from "../actions/types";
import { isEmpty } from "lodash";

const initialState = {
  users: [],
  user: null
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.user),
        user: action.user
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload
      };
    case UPDATE_USER:
      return {
        ...state,
        users: state.users.map(user => {
          let updatedUser = action.payload.user;
          if (user._id === action.payload.id) {
            user = updatedUser;
          }
          return user;
        })
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter(user => user._id !== action.payload)
      };
    default:
      return state;
  }
};
