
// calculate the total
export const total = (obj, prop) => {
  return obj.reduce((acc, curr) => {
    const amountProp = parseFloat(curr[prop]);
    return (parseFloat(acc) + amountProp).toFixed(2);
  }, 0);
}

// group all items by their months
export const groupByMonth = (data, property) => {
  return data.reduce((acc, obj) => {
    const key = obj[property];
    const date = new Date(key)
    const month = date.getMonth();
    if (!acc[month]) acc[month] = [];
    acc[month].push(obj);
    return acc;
  }, {});
}
  
// convert numeric month to month name
const monthIndexToName = monthIndex => {
  const MONTHS = [
    'January', 'February', 'March', 'April', 'May',
    'June', 'July', 'August', 'September',
    'October', 'November', 'December'
  ];
  return (MONTHS[monthIndex] || '');
}
  
// calculate the total for each month and return an object of all months
export const getMonthlyExpenses = arr => {
  const allMonths = {}
  const data = groupByMonth(arr, 'addedOn');
  for(let i in data) {
    const monthTotal = total(data[i], 'amount');
    const mounthName = monthIndexToName(i)
    allMonths[mounthName] = monthTotal;
  }
  return allMonths;
}
  
export const sortBy = (data, field, desc = true) => {
  if(!data) return;
  if(!field) return data;
  return data.sort((a, b) => {
    if(field === "amount") return desc ? (b[field] - a[field]) : (a[field] - b[field]);
    const start = desc ? a[field].toUpperCase() : b[field].toUpperCase();
    const end = desc ? b[field].toUpperCase() : a[field].toUpperCase();
    if(start < end) return -1;
    if(start > end) return 1;
    return 0;
  });
}

export const stringFormat = data => {
    return data.replace(/\\n/g, "/<br ///>");
    //           .replace(/\\'/g, "\\'")
   //            .replace(/\\"/g, '\\"')
   //            .replace(/\\&/g, "\\&")
  //             .replace(/\\r/g, "\\r")
  //             .replace(/\\t/g, "\\t")
  //             .replace(/\\b/g, "\\b")
  //             .replace(/\\f/g, "\\f");
};
