import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class FlashMessage extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.deleteFlashMessage(this.props.message.id);
  }
  render() {
    const { type, text } = this.props.message;
    return (
      <div className={classnames({
        'alert-success': type === 'success',
        'alert-danger': type === 'error'
      })}>
        <button className="flash-msg-close" onClick={this.handleClick}><span>&times;</span></button>
        {text}
      </div>
    );
  }
}

FlashMessage.propTypes = {
  message: PropTypes.object.isRequired,
  deleteFlashMessage: PropTypes.func.isRequired
}

export default FlashMessage;