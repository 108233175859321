import {
  GET_APPLICATIONS,
  ADD_APPLICATION,
  DELETE_APPLICATION,
  UPDATE_APPLICATION
} from "../actions/types";

const initialState = {
  jobs: []
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_APPLICATIONS:
      return {
	...state,
        jobs: action.payload
      };
    case ADD_APPLICATION:
      return {
      	...state,
        jobs: [action.payload, ...state.jobs]
      };
    case UPDATE_APPLICATION:
      return {
      	...state,
        jobs: state.jobs.map(job => {
          if (job._id === action.payload.id) {
            job = Object.assign(job, action.payload.job);
          }
          return job;
        })
      };
    case DELETE_APPLICATION:
      return {
        jobs: state.jobs.filter(job => job._id !== action.payload)
      };
    default:
      return state;
  }
};
