import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { addFlashMessage } from "../../actions/flashMessages";
import { addApplication } from "../../actions/application";
import FlashMessagesList from "../../flash/FlashMessagesList";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      skills: "",
      notes: "",
      description: "",
      company: "",
      appliedOn: new Date(),
      feedback: "",
      isSubmited: false,
      error: {}
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    console.log(this.state);
    this.props.addApplication(this.state);
    this.props.addFlashMessage({
      type: "success",
      text: "Application added."
    });
    document.forms.jobAdd.reset();
    this.setState({ isSubmited: true });
  };

  render() {
    // console.log(this.state)
    // let { isSubmited } = this.state;
    return (
      <div className='form-wrapper'>
        <h2>Add an Application</h2>
        <form
          className='job-add-form'
          name='jobAdd'
          onSubmit={this.handleSubmit}
        >
          <label className='job-add'>
            Position:
            <input
              onChange={this.handleChange}
              type='text'
              name='title'
              placeholder='Position'
            />
          </label>

          <label className='job-add'>
            Company:
            <input
              onChange={this.handleChange}
              type='text'
              name='company'
              placeholder='Company'
            />
          </label>

          <label className='job-add'>
            Skills:
            <input
              onChange={this.handleChange}
              type='text'
              name='skills'
              placeholder='Skills'
            />
          </label>
          <label className='job-add'>
            Applied on:
            <input
              onChange={this.handleChange}
              type='date'
              name='appliedOn'
              placeholder='Applied on'
            />
          </label>
          <label className='job-add'>
            Notes:
            <textarea
              onChange={this.handleChange}
              type='text'
              name='notes'
              placeholder='Add notes'
            />
          </label>
          <label className='job-add'>
            Description:
            <textarea
              onChange={this.handleChange}
              type='text'
              name='description'
              className='desc'
              placeholder='Description'
            />
          </label>
          <button className='job-add'>SUBMIT</button>
          <FlashMessagesList />
        </form>
        {/* {!this.state.isOpen && (
          <Redirect to={`/applications`}/>
        )} */}
      </div>
    );
  }
}

Form.propTypes = {
  title: PropTypes.string,
  skills: PropTypes.string,
  description: PropTypes.string,
  company: PropTypes.string,
  appliedOn: PropTypes.string,
  addFlashMessage: PropTypes.func.isRequired
};

export default connect(
  null,
  { addFlashMessage, addApplication }
)(Form);
