// import {
//   GET_EXPENSES,
//   ADD_EXPENSE,
//   UPDATE_EXPENSE,
//   DELETE_EXPENSE
// } from "../actions/types";

const initialState = {
  expenses: []
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "GET_EXPENSES":
      return {
	...state,
        expenses: action.payload
      };
    case "ADD_EXPENSE":
      return {
      	...state,
        expenses: [action.payload, ...state.expenses]
      };
    case "UPDATE_EXPENSE":
      return {
      	...state,
        expenses: state.expenses.map(expense => {
          if (expense._id === action.payload.id) {
            expense = Object.assign(expense, action.payload.expense);
          }
          return expense;
        })
      };
    case "DELETE_EXPENSE":
      return {
        expenses: state.expenses.filter(
          expense => expense._id !== action.payload
        )
      };
    default:
      return state;
  }
};
