import React, { Component } from "react";
//import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getUsers } from "../../actions/signup";
import Layout from "../Layout";
import SignedUp from "./SignedUp";
class SignedUpList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      errors: {}
    };
  }

  componentWillMount() {
    document.title = "Registration List | nJustin";
    this.props.getUsers()
      .catch(err => this.setState({errors: err.response.data}));
  }

  render() {
    const users = this.props.users;
    const { error } = this.state.errors;
    return (
      <Layout>
        <h2 className='signedup'>Registration List</h2>
        {error && <div className='error-auth'>{error}</div>}
        {!users ? (
          <div className='loading'>No user found!</div>
        ) : (
          <table className='data-table'>
            <thead>
              <tr>
                <th className=''>Date</th>
                <th>Name</th>
                <th className='hide-on-sm'>Email</th>
                <th className='hide-on-sm'>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            {users.map((user, index) => (
              <SignedUp key={index} user={user} />
            ))}
          </table>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  users: state.auth.users
});

export default connect(
  mapStateToProps,
  { getUsers }
)(SignedUpList);
