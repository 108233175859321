import React, { Component } from "react";
import { connect } from "react-redux";
import { updateUser, deleteUser } from "../../actions/signup";

class SignedUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      users: []
    };
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value });
  }

  handleAction = () => {
    const action = this.state.value;
    if (action === "approve")
      this.props.updateUser(this.props.user._id, { verified: true });
    if (action === "revoque")
      this.props.updateUser(this.props.user._id, { verified: false });
    if (action === "delete") this.props.deleteUser(this.props.user._id);
    this.setState({ value: "" });
  }
  render() {
    const { joinedOn, name, username, verified } = this.props.user;
    const status = verified ? "Approved" : "Pending";
    return (
      <tbody>
        <tr>
          <td className=''>{joinedOn.split("T")[0]}</td>
          <td>{name}</td>
          <td className='hide-on-sm'>{username}</td>
          <td className='hide-on-sm'>{status}</td>
          <td>
            <select value={this.state.value} onChange={this.handleChange}>
              <option value=''>-- Select --</option>
              <option value='approve'>Approve</option>
              <option value='revoque'>Revoque</option>
              <option value='delete'>Delete</option>
            </select>
            <button onClick={this.handleAction}><i className="fas fa-arrow-alt-circle-right"></i></button>
          </td>
        </tr>
      </tbody>
    );
  }
}

const mapStateToProps = state => ({
  users: state.auth.users
});

export default connect(
  mapStateToProps,
  { updateUser, deleteUser }
)(SignedUp);
