import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";

import { hideModal } from "../actions/modal";
import ContactForm from "./ContactForm";

class Modal extends Component {

  closeModal = (e) => {
    e.stopPropagation();
    if (e.target === e.currentTarget) {
      this.props.hideModal();
    }
  }

  render() {
    return (
      <div
        className={classnames("contact-modal", { "modal-open": this.props.isOpen })}
        onClick={this.closeModal}
      >
        <div className='modal-view'>
          <ContactForm />
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  isOpen: state.isOpen.isOpen
});

export default connect(
  mapStateToProps,
  { hideModal }
)(Modal);
