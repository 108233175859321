import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlashMessage from './FlashMessage';
import { connect } from 'react-redux';
import { deleteFlashMessage } from '../actions/flashMessages';

class FlashMessagesList extends Component {
  render() {
    const { deleteFlashMessage } = this.props;
    const messages = this.props.messages.map(message => {
      return (
        <FlashMessage 
          key={message.id} 
          message={message} 
          deleteFlashMessage={deleteFlashMessage}
        />
      );
    });
    return (
      <div className="alert-container">
        {messages}
      </div>
    );
  }
}

FlashMessagesList.propTypes = {
  messages: PropTypes.array.isRequired,
  deleteFlashMessage: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    messages: state.flashMessages
  };
}

export default connect(mapStateToProps, { deleteFlashMessage })(FlashMessagesList);