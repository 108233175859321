import React from 'react';
// import Header from './Header';
// import Footer from './Footer';

const Notfound = () => {
  return (
  <main>
    {/* <Header /> */}
    <div className="modal-view">
      <div id='error-page'>
        <div id='error-inner'>
          <h1> OOPS! PAGE NOT FOUND.</h1>
          <div className="pesan-error">404</div>
          <p className="balik-home"><a href="/">Back to Home Page!</a></p><br/>
        </div>
      </div>
    </div>
    {/* <Footer /> */}
  </main>);
}

export default Notfound;