import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import {
  getExpenses,
  updateExpense,
  deleteExpense
} from "../../actions/expense";
import { logout } from "../../actions/login";
import { showModal, hideModal } from "../../actions/modal";
import Expense from "./Expense";
// import Loading from "../Loading";
import Pagination from './Pagination';
import { groupByMonth, getMonthlyExpenses, sortBy } from "./helper";


class ExpensesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSorted: false,
      //currentPage: Date(props.expenses[props.expenses.length - 1].addedOn).getMonth(),
      currentPage: 0, //|| (new Date()).getMonth(),
      //currentPage: (Object.keys(getMonthlyExpenses(this.props.expenses))).length - 1, 
      itemsPerPage: [],
      groupedItems: [],
      currentItems: [],
      isDesc: true,
      error: {}
    };
  }

  componentWillMount() {
    document.title = "Expenses | nJustin";  	
    // this.setState({ isLoading: true })
    this.props.getExpenses()//.catch(err => this.setState({ error: err.response.data }));
    if(this.state.error.name === "TokenExpiredError") return this.props.logout();
  }
  
  /*
  componentDidUpdate(prevState) {
    if(prevState.currentPage === 0) {
      this.setState({ currentPage: (Object.keys(getMonthlyExpenses(this.props.expenses))).length - 1 })
    }
  }
*/
  handleSort = (items, e) => {
    e.preventDefault();
    //let isDesc = true;
    const target = e.currentTarget;
    const fieldToSort = target.dataset.sortby;
    //console.log(target.innerText);
    //target.parentNode.children.map(el => el.lastChild.classList.remove("reverse"));
    //console.log(target.parentNode.children);
    //target.lastChild.classList.toggle("reverse");
    this.setState({ 
      currentItems: sortBy(items, fieldToSort, this.state.isDesc),
      isSorted: true,
      isDesc: !this.state.isDesc
    });
  }

  paginate = currentPage => this.setState({ currentPage, isSorted: false });

  render() {
    const { expenses, updateExpense, deleteExpense, isOpen, showModal, hideModal } = this.props;
    const { currentPage, currentItems, isSorted } = this.state;   
    const allGroupedItems = groupByMonth(expenses, 'addedOn');
    const expensesStatus = getMonthlyExpenses(expenses);
    const currentMonth = Object.keys(expensesStatus)[currentPage];
    const currentPageItems = allGroupedItems[Object.keys(allGroupedItems)[currentPage]];

//console.log((Date(expenses[0].addedOn)).getMonth;
//console.log(this.state.error)
    const arrowIcon = isSorted ? 'sorted' : '';
    const sortedItems = isSorted ? currentItems : currentPageItems;
    const itemsDisplay = sortedItems && sortedItems.map(expense => (
      <Expense
        key={expense._id}
	isOpen={isOpen}
        expense={expense}
	showModal={showModal}
	hideModal={hideModal}
        updateExpense={updateExpense}
        deleteExpense={deleteExpense}
      />
    ));
    
    return (
      <div className="xpense-container">
        <h2 className='signedup'>{currentMonth} Expenses</h2>
        {!expenses ? (
          <div className='loading'>No expense found!</div>
        ) : (
          <div>
            <table className='data-table'>
              <thead>
                <tr>
                  <th onClick={(e) => this.handleSort(currentPageItems, e)} data-sortby="addedOn" className=''>Date<span className="sort-box"><i className="fas fa-play"></i><i className="fas fa-play"></i></span></th>
                  <th onClick={(e) => this.handleSort(currentPageItems, e)} data-sortby="expense">Expense<span className="sort-box"><i className="fas fa-play"></i><i className="fas fa-play"></i></span></th>
                  <th onClick={(e) => this.handleSort(currentPageItems, e)} data-sortby="category" className='hide-on-sm'>Category<span className="sort-box"><i className="fas fa-play"></i><i className="fas fa-play"></i></span></th>
                  <th onClick={(e) => this.handleSort(currentPageItems, e)} data-sortby="amount" className=''>Amount<span className="sort-box"><i className="fas fa-play"></i><i className="fas fa-play"></i></span></th>
                  <th onClick={(e) => this.handleSort(currentPageItems, e)} data-sortby="payee" className='hide-on-sm'>Payee<span className="sort-box"><i className="fas fa-play"></i><i className="fas fa-play"></i></span></th>
		  <th></th>
                </tr>
              </thead>
              {/* {isLoading && <Loading />} */}
              
              <tbody>{itemsDisplay}</tbody>

            </table>
              <div className="monthly-expense-wrapper">
                {itemsDisplay && 
                <Pagination
                  expensesStatus={expensesStatus}
                  paginate={this.paginate} 
                  currentMonth={currentMonth}
                />}
              </div>            
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  expenses: state.expenses.expenses,
  isOpen: state.isOpen.isOpen
});

export default connect(
  mapStateToProps,
  { getExpenses, updateExpense, deleteExpense, logout, showModal, hideModal }
)(ExpensesList);
