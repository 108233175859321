import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updateApplication } from "../../actions/application";
class JobAppEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.job.title,
      skills: props.job.skills,
      description: props.job.description,
      notes: props.job.notes,
      company: props.job.company,
      appliedOn: props.job.appliedOn,
      feedback: props.job.feedback,
      isOpen: true
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    // const updatedJobApp = this.state;
    const jobId = this.props.job._id;
    this.props.updateApplication(jobId, this.state);
    // this.updateJobApplication(updatedJobApp, jobId);
    this.setState({ isOpen: false });
  };

  render() {
    const {
      title,
      company,
      description,
      notes,
      skills,
      appliedOn,
      feedback
    } = this.state;
    const dateApplied = appliedOn ? appliedOn.split("T").slice(0, 1) : "";
    const desc = description ? description : "";
    const note = notes ? notes : "";
    const editForm = this.state.isOpen ? this.props.onEdit : "desc-content";

    return (
      <ul className={editForm}>
        <h2>Update Job Application</h2>
        <form name='jobEdit' onSubmit={this.handleSubmit}>
          <div className='jb-edit'>
            <span>Position: </span>
            <input
              className='jb-edit-prop'
              type='text'
              name='title'
              value={title}
              onChange={this.handleChange}
            />
          </div>
          <div className='jb-edit'>
            <span>Company: </span>
            <input
              className='jb-edit-prop'
              type='text'
              name='company'
              value={company}
              onChange={this.handleChange}
            />
          </div>
          <div className='jb-edit'>
            <span>Skills: </span>
            <input
              className='jb-edit-prop'
              type='text'
              name='skills'
              value={skills}
              onChange={this.handleChange}
            />
          </div>
          <div className='jb-edit'>
            <span>Applied on: </span>
            <input
              className='jb-edit-prop'
              type='date'
              name='appliedOn'
              value={dateApplied}
              onChange={this.handleChange}
            />
          </div>
          <div className='jb-edit'>
            <span>Feedback: </span>
            <input
              className='jb-edit-prop'
              type='date'
              name='feedback'
              value={feedback}
              onChange={this.handleChange}
            />
          </div>
          <div className='jb-edit'>
            <span>Notes: </span>
            <br />
            <textarea
              className='jb-edit-prop'
              name='notes'
              value={note}
              onChange={this.handleChange}
            />
          </div>
          <div className='jb-edit'>
            <span>Job description: </span>
            <br />
            <textarea
              className='jb-edit-prop desc'
              name='description'
              value={desc}
              onChange={this.handleChange}
            />
          </div>
          <button className='job-edit'>UPDATE</button>
        </form>
      </ul>
    );
  }
}

JobAppEdit.propTypes = {
  title: PropTypes.string,
  skills: PropTypes.string,
  description: PropTypes.string,
  company: PropTypes.string,
  appliedOn: PropTypes.string,
  feedback: PropTypes.string
};

const mapStateToProps = state => ({
  isOpen: state.isOpen.isOpen
});

export default connect(
  mapStateToProps,
  { updateApplication }
)(JobAppEdit);
