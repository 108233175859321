import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/login";

// const onTokenExpire = ({exp, iat}) => {
//   this.props.logout();
//   return <Redirect to="/users/login" />
// }

const PrivateRoute = ({ component: Component, auth, ...rest }) => {

  const authProp = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const { isAuthenticated, user } = authProp;
  
  useEffect(() => {
    const onTokenExpire = () => {
      if(Date.now() >= user.exp * 1000) 
        dispatch(logout());
    }
    onTokenExpire();
  }, [user]);

  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated ? (
          <Redirect
            to={{
              pathname: "/users/login",
              state: { from: props.location }
            }}
          />
        ) : <Component {...props} />
      }
    />
  );
};

// const mapStaeToProps = state => ({
//   auth: state.auth
// })

export default PrivateRoute;
