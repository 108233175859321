import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  updateApplication,
  deleteApplication
} from "../../actions/application";
import { EditIcon, DeleteIcon } from "../../assets/icons/Icons";
import JobAppDetail from "./JobAppDetail";
import JobAppEdit from "./JobAppEdit";

class JobApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      onEdit: false
    };
  }

  handleExpand = e => {
    e.stopPropagation();
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  handleEdit = e => {
    e.stopPropagation();
    const jobId = this.props.job._id;
    this.setState({ onEdit: !this.state.onEdit });
    this.props.updateApplication(jobId);
  };

  handleDelete = e => {
    if (e) e.stopPropagation();
    const jobId = this.props.job._id;
    const w = (React.window = window);
    w.scrollTo(250, 100);
    if (
      w.confirm(
        `Do you really want to delete ${this.props.job.title} application?`
      )
    ) {
      this.props.deleteApplication(jobId);
    }
  };

  render() {
    let { index, job } = this.props;
    const { title, company, skills, appliedOn, feedback } = job;
    const { isExpanded, onEdit } = this.state;
    const dateApplied = appliedOn ? appliedOn.split("T").slice(0, 1) : "";

    //handle events
    const applicationDetail = isExpanded ? "desc-content on" : "desc-content";
    const applicationEdit = onEdit ? "desc-content on" : "desc-content";
    return (
      <ul className="job-app-box">
        <ul className="jb-wrapper" onClick={this.handleExpand}>
          <li className="jb-index">{++index}.</li>
          <li className="jb-title">
            {title && title.length > 14
              ? `${title.substring(0, 14)}...`
              : title}
          </li>
          <li className="jb-company">
            {company && company.length > 16
              ? `${company.substring(0, 16)}...`
              : company}
          </li>
          <li className="jb-skills">
            {skills && skills.length > 14
              ? `${skills.substring(0, 14)}...`
              : skills}
          </li>
          <li className="jb-applied-on">{dateApplied}</li>
          <li className="jb-feedback">{feedback}</li>
          <li className="jb-icons">
            <span className="edit-icon" onClick={this.handleEdit}>
              <EditIcon />
            </span>
            <span className="delete-icon" onClick={this.handleDelete}>
              <DeleteIcon />
            </span>
          </li>
        </ul>
        {isExpanded && (
          <JobAppDetail onExpanded={applicationDetail} job={job} />
        )}
        {onEdit && <JobAppEdit onEdit={applicationEdit} job={job} />}
      </ul>
    );
  }
}

JobApp.propTypes = {
  updateApplication: PropTypes.func.isRequired,
  deleteApplication: PropTypes.func.isRequired,
  job: PropTypes.object
};

const mapStateToProps = state => ({
  jobs: state.jobs.jobs
});

export default connect(mapStateToProps, {
  updateApplication,
  deleteApplication
})(JobApp);
