import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
//this allows browser to cache our app so they can work with when they don't have internet
//but sometimes when you push updates, they might not get them because they are working with the cached version
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
