import axios from "axios";
import {
  GET_APPLICATIONS,
  ADD_APPLICATION,
  DELETE_APPLICATION,
  UPDATE_APPLICATION
} from "./types";

export const getApplications = () => dispatch => {
  axios
    .get("/applications")
    .then(res => {
      return dispatch({
        type: GET_APPLICATIONS,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};

export const addApplication = newJob => dispatch => {
  axios
    .post("/applications", newJob)
    .then(res => {
      return dispatch({
        type: ADD_APPLICATION,
        payload: res.data
      });
    })
    .catch(err => console.error(err));
};
export const updateApplication = (id, updatedApplication) => dispatch => {
  axios
    .put(`/applications/${id}`, updatedApplication)
    .then(res => {
      return dispatch({
        type: UPDATE_APPLICATION,
        payload: { id, job: res.data }
      });
    })
    .catch(err => console.log(err));
};

export const deleteApplication = id => dispatch => {
  axios
    .delete(`/applications/${id}`)
    .then(() => {
      return dispatch({
        type: DELETE_APPLICATION,
        payload: id
      });
    })
    .catch(err => console.log(err));
};
