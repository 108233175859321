import React from 'react'

const Pagination = ({ expensesStatus, paginate, currentMonth}) => {
    const keys = Object.keys(expensesStatus);
    const currentYear = 2019;
    return (
        <>
            <ul className="pg-footer-wrapper">
                {keys.map(key => {
                    const index = keys.indexOf(key);
                    const pgFooterContent = key === currentMonth ? "pg-footer-content active" : "pg-footer-content";
                    //const pageIndex = keys.length - index;
                    return(
                    <li key={index} onClick={() => paginate(index)} className={pgFooterContent}>
                        <span>{key.substr(0,3)}&nbsp;{currentYear}</span>
                        <button className="monthly-expense"><b>${expensesStatus[key]}</b></button>
                    </li>
                    )
                })
            }    
            </ul>
        </>
    )
}

export default Pagination
