import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getApplications } from "../../actions/application";
import JobApp from "./JobApp";
import Loading from "../Loading";

class JobList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.props.getApplications();
    this.setState({ isLoading: false });
  }

  render() {
    const { jobs } = this.props;
    const AllJobs = jobs.map((job, index) => (
      <JobApp key={index} job={job} index={index} />
    ));

    return (
      <div className='table-data'>
        {this.state.isLoading ? (
          <Loading />
        )/* : !jobs ? (
          <div className='loading'>No application found!</div>
        )*/ : (
          AllJobs
        )}
      </div>
    );
  }
}

JobList.propTypes = {
  getApplications: PropTypes.func.isRequired,
  jobs: PropTypes.array
};

const mapStateToProps = state => ({
  jobs: state.jobs.jobs
});

export default connect(
  mapStateToProps,
  { getApplications }
)(JobList);
