import React from 'react';

const TableHeader = () => {
  return (
    <div className="tb-header">
      <ul className="jb-hd">
        <li className="jb-index">#&nbsp;&nbsp;</li>
        <li className="jb-title">POSITION</li>
        <li className="jb-company">COMPANY</li>
        <li className="jb-skills">SKILLS</li>
        <li className="jb-applied-on">DATE</li>
        <li className="jb-feedback">FEEDBACK</li>
        <li className="jb-icons">ACTION</li>
      </ul>
    </div>
  );
}

export default TableHeader;