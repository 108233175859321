import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Home from "./Home";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "./Dashboard";
import Applications from "./jat/Applications";
import Table from "./jat/Table";
import SignupPage from "./signup/SignupPage";
import LoginPage from "./login/LoginPage";
import Notfound from "./Notfound";
import Contact from "./Contact";
import SignedUpList from "./mgmt/SignedUpList";
import Expenses from "./xpense/";

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/users/new' component={SignupPage} />
        <Route exact path='/users/login' component={LoginPage} />
        <PrivateRoute exact path='/dashboard' component={Dashboard} />
        <PrivateRoute exact path='/applications' component={Table} />
        <PrivateRoute exact path='/users' component={SignedUpList} />
        <PrivateRoute exact path='/xpenses' component={Expenses} />
        <PrivateRoute exact path='/applications/new' component={Applications} />
        <Route exact path='/contact' component={Contact} />
        <Route component={Notfound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
