
import { OPEN_MODAL, CLOSE_MODAL } from './types';

export const showModal = () =>  dispatch => {
  dispatch({ type: OPEN_MODAL });
}

export const hideModal = () =>  dispatch => {
  dispatch({ type: CLOSE_MODAL });
}

// export const showModal = () =>  dispatch => {
//   ({
//     dispatch(openModal());
//   });
// }

// export const hideModal = () =>  dispatch => {
//   (dispatch => {
//     dispatch(closeModal());
//   })
// }