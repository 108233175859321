import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Contact extends Component {
  state = {
    result: []
  }

  componentDidMount() {
    fetch("/contact", 
    {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
          "Content-Type": "application/json",
          // "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then(res => res.json())
      .then(data => this.setState({ result: data }))
  }

  render() {
    return (
        <p className="result-txt">{this.state.result.msg}<br />
        Click&nbsp;<Link to="/">here</Link>
          &nbsp;to go back to Home Page.
        </p>
    );
  }
}

export default Contact;
