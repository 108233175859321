import React, { Component } from "react";
// import PropTypes from "prop-types";

import Layout from "../Layout";
import SignupForm from "./SignupForm";
// import { Redirect } from 'react-router-dom';
// import JobList from '../jat/JobList';

class SignupPage extends Component {

  componentWillMount() {
    document.title = "Sign up | nJustin";
  }

  render() {
    return (
      <Layout>
        <div className='table-wrapper signup'>
          <div className='form-wrapper'>
            <h2>User registration</h2>
            <SignupForm />
            {/* {isLoggedIn && (
              <Redirect to={`/applications`}/>
            )} */}
          </div>
        </div>
      </Layout>
    );
  }
}

export default SignupPage;
