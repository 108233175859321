import axios from "axios";
import { GET_USERS, UPDATE_USER, DELETE_USER, GET_ERRORS } from "./types";

export const onUserSignup = newUser => dispatch =>
  axios.post("/users", newUser);

export const getUsers = () => dispatch => {
  return axios
    .get("/users")
    .then(res => {
      dispatch({
        type: GET_USERS,
        payload: res.data
      });
    })
};
export const updateUser = (userId, updatedUser) => dispatch => {
  return axios
    .put(`/users/${userId}`, updatedUser)
    .then(res => {
      dispatch({
        type: UPDATE_USER,
        payload: { id: userId, user: res.data }
      });
    })
};

export const deleteUser = id => dispatch => {
  return axios
    .delete(`/users/${id}`)
    .then(() => {
      dispatch({
        type: DELETE_USER,
        payload: id
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};
