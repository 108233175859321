import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Layout from "../Layout";
import Form from './Form';
import { addFlashMessage } from '../../actions/flashMessages';

import '../../assets/css/table.css';

class Applications extends Component {

  componentWillMount() {
    document.title = "Add an application | nJustin";
  }
  
  render() {
    return (
      <Layout>
        <div className="table-wrapper jb-add">
          <Form addFlashMessage={addFlashMessage} />
        </div>
      </Layout>
    );
  }
}

Applications.propTypes = {
  addFlashMessage: PropTypes.func
}

export default Applications;
